$primary-color: #3f51b5;

.header{
    border-bottom: 1px solid rgba(0,0,0,.1);
    user-select: none;

    .content{
        display: flex;
    }

    .titles{
        height: 2em;
        display: flex;
        align-items: center;

        h1{
            font-size: 1em;
            font-weight: normal;
            margin: 0 0.75em 0 0;
            padding-left: 0.35em;
        }

        span.type{
            font-size: small;
            font-weight: normal;
            margin: 0 0 0.1em 0;
            color: #333;

            .plan-id{
                color: $primary-color;
            }
        }
    }

    .menu{
        font-size: 0.9em;

        button{
            font-size: 0.9em;
            font-family: inherit;
            cursor: pointer;
            background-color: white;
            border: none;
            border-radius: 2px;
            padding: 0.25em 0.5em;
            outline: none;
            color: #888;
            transition: .2s;

            &:hover{
                color: #333;
                background-color: #f2f2f2;
            }

            &.selected{
                color: $primary-color;
            }
        }
    }
}