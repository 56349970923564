section.view-login{
	background-color: #B721FF;
	background-image: linear-gradient(19deg, #153a5e 0%, #2a76bf 100%);
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: auto;
	user-select: none;
	min-height: 100vh;

	main{
		& > .logo{
			margin-bottom: 1em;
			max-width: 500px;
			height: 100px;
			overflow: hidden;
			display: flex;
			align-items: center;
			justify-content: center;

			img{
				width: 100%;
				height: 100%;
				object-fit: contain;
				margin: 0;
			}
		}

		.copy{
			margin-top: 1em;

			.copy-content{
				padding: 16px;
				text-align: center;

				.logo{
					display: inline-block;
					height: 15px;
					margin-bottom: -2px;
				}
			}
		}
	}
}