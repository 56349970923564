$primary-color: #3f51b5;
$primary-color-alt: #3849a1;
$primary-color-ligthen: #979fd1;

.react-datepicker-container{
    position: relative;
    z-index: 100;

    .react-datepicker-toggle{
        opacity: 0;
        visibility: hidden;
        transition: .2s;

        &.show{
            opacity: 1;
            visibility: visible;
        }
    }

    .button-icon{
        margin-top: -2px;
    }
}

.react-datepicker{
    margin: 0;
    border: 0;
    overflow: hidden;
    border-radius: 2px;
    position: absolute;
    top: 100%;

    .react-datepicker__triangle{
        display: none;
    }

    .react-datepicker{
        border: none;
        border-radius: 1px;
        display: flex;
        background-color: white;
        overflow: hidden;

        &:after{
            content: '';
            clear: both;
        }
    }

    .react-datepicker__navigation{
        position: inherit;
        outline: none;
        transition: .2s;

        &.react-datepicker__navigation--next{
            border-left-color: rgba(255,255,255,0.75);
            cursor: pointer;

            &:hover{
                border-left-color: rgba(255,255,255,1);
            }
    
            &:active{
                border-left-color: rgba(255,255,255,0.5);
            }

            &:disabled, &[disabled]{
                border-left-color: rgba(255,255,255,0.2);
                cursor: default;
            }
        }

        &.react-datepicker__navigation--previous{
            border-right-color: rgba(255,255,255,0.75);
            cursor: pointer;

            &:hover{
                border-right-color: rgba(255,255,255,1);
            }
    
            &:active{
                border-right-color: rgba(255,255,255,0.5);
            }

            &:disabled, &[disabled]{
                border-right-color: rgba(255,255,255,0.2);
                cursor: default;
            }
        }
    }

    .react-datepicker__month-container{
        float: none;

        .react-datepicker__month{
            .react-datepicker__day{
                width: 32px;
                height: 26px;
                line-height: 26px;
                border-radius: 2px;
                margin: 0;
                outline: none;
                background-color: transparent;
                margin-bottom: 2px;
            }
    
            .react-datepicker__day--keyboard-selected{
                color: $primary-color;
                font-weight: bold;
            }
    
            .react-datepicker__day--in-range .box-day{
                background-color: $primary-color;
            }
    
            .react-datepicker__day--selected .box-day,
            .react-datepicker__day--range-start .box-day,
            .react-datepicker__day--range-end .box-day{
                background-color: $primary-color;
                color: white;
                border-radius: 2px;
            }
    
            .react-datepicker__day--range-start .box-day-wrapper,
            .react-datepicker__day--range-start .box-day{
                border-radius: 13px 0 0 13px;
            }
    
            .react-datepicker__day--range-end .box-day-wrapper,
            .react-datepicker__day--range-end .box-day{
                border-radius: 0 13px 13px 0;
            }
    
            &.react-datepicker__month--selecting-range{
                .react-datepicker__day--in-range{
                    .box-day-wrapper{
                        background-color: $primary-color-ligthen;
                        color: white;
                    }
                    .box-day{
                        background-color: transparent;
                    }
                }
                .react-datepicker__day--selected,
                .react-datepicker__day--range-start,
                .react-datepicker__day--range-end{
                    .box-day-wrapper{
                        background-color: $primary-color-ligthen;
                        color: white;
                    }
                    .box-day{
                        background-color: transparent;
                    }
                }
            }
    
            .react-datepicker__day--in-selecting-range .box-day{
                background-color: $primary-color !important;
                color: white;
                border-radius: 0;
            }
            .react-datepicker__day--selecting-range-start .box-day{
                background-color: $primary-color;
                border-radius: 13px 0 0 13px;
            }
            .react-datepicker__day--selecting-range-end .box-day{
                background-color: $primary-color;
                border-radius: 0 13px 13px 0;
            }
        }
    }

    
    .react-datepicker__time-container{
        float: none;
        border: 0;
        background-color: #eee;

        .react-datepicker__time{
            background-color: transparent;
        }

        .react-datepicker__time-list-item{
            display: flex;
            align-items: center;
            justify-content: center;

            &.react-datepicker__time-list-item--selected{
                background-color: $primary-color !important;
                color: white;
            }
        }

    }
    

    .react-datepicker__header{
        background-color: $primary-color;
        border-radius: 0;
        border: 0;
        color: white;

        &.react-datepicker__header--time{
            background-color: $primary-color-alt;
        }

        .react-datepicker__current-month{
            color: inherit;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .react-datepicker__day-name{
            color: inherit;
        }
    
        .react-datepicker-time__header{
            color: inherit;
        }
    }
}