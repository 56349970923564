$primary-color: #3f51b5;
$viewer-bg-color: #090a0f;

.timelapse-viewer{
    .viewer-frame {
        position: relative;
        min-height: 500px;
        background-color: $viewer-bg-color;
        border-radius: 2px;
        overflow: hidden;
        color: white;
        margin-bottom: 0.4em;

        .viewer-loading{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            user-select: none;
            
            .viewer-loading-content{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .text{
                    margin-top: 1em;
                    color: rgba(white, 0.5);
                }
            }
        }

        .viewer-image-container-outer {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .viewer-image-container-inner {
                aspect-ratio: 1024 / 768;
                background-color: #000;
                width: 800px;
                max-width: 100%;
                max-height: 100%;
                margin: auto;

                .viewer-image-container-inner-message {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    color: rgba(#ffffff, 0.2);
                    user-select: none;
                    
                    .error-icon{
                        margin-bottom: 0.5em;
                    }
                }

                .viewer-image-container-inner-image{
                    width: 100%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }

        .viewer-toolbar {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: transparent;
            background: linear-gradient(0deg, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0) 100%);
            display: flex;
            flex-direction: column;
            z-index: 2;

            .viewer-toolbar-progress{
                width: 100%;
                flex: 2;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 0.6em;

                .progress-container {
                    width: 100%;
                    background-color: rgba($primary-color, 0.1);
                    padding: 4px;
                    height: 16px;
                    border-radius: 8px;

                    .progress-bar{
                        position: relative;
                        height: 8px;

                        progress:not([value]){
                            visibility: hidden;
                            opacity: 0;
                        }

                        progress[value].progress-bar-hover {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 8px;
                            margin: 0;
                            z-index: 1;
                            appearance: none;
                            border-radius: 4px;
                            overflow: hidden;
                            visibility: hidden;
                            opacity: 0;
                            transition: .2s;

                            &.visible{
                                visibility: visible;
                                opacity: 1;
                            }

                            &::-webkit-progress-bar{
                                background-color: transparent;
                            }

                            &::-webkit-progress-value{
                                transition: .1s;
                                background-color: rgba(#ffffff, 0.1);
                                border-radius: 4px;
                            }
                        }

                        progress[value].progress-bar-progress {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 8px;
                            margin: 0;
                            z-index: 2;
                            appearance: none;
                            border-radius: 4px;
                            overflow: hidden;

                            &::-webkit-progress-bar{
                                background-color: transparent;
                            }

                            &::-webkit-progress-value{
                                background-color: $primary-color;
                            }
                        }

                        input[type=range] {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 8px;
                            margin: 0;
                            appearance: none;
                            background-color: transparent;
                            z-index: 3;
                            outline: none;

                            &::-webkit-slider-thumb{
                                appearance: none;
                                width: 8px;
                                height: 8px;
                                margin-top: 2px;
                                background-color: $primary-color;
                                border-radius: 6px;
                                transition: .2s;
                                cursor: pointer;
                            }

                            &::-moz-slider-thumb{
                                width: 8px;
                                height: 8px;
                                margin-top: 2px;
                                background-color: $primary-color;
                                border-radius: 6px;
                                transition: .2s;
                                cursor: pointer;
                            }

                            &:hover::-webkit-slider-thumb{
                                transform: scale(2);
                            }

                            &:hover::-moz-slider-thumb{
                                transform: scale(2);
                            }

                            &::-webkit-slider-thumb:hover{
                                background-color: lighten($primary-color, 10%);
                            }

                            &::-moz-slider-thumb:hover{
                                background-color: #4c62dd;
                            }

                            &::-webkit-slider-runnable-track {
                                width: 100%;
                                height: 12px;
                            }

                            &::-moz-range-track {
                                width: 100%;
                                height: 12px;
                            }
                        }
                    }
                }
            }

            .viewer-toolbar-info {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0.4em;

                .viewer-toolbar-box{
                    width: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .viewer-toolbar-step,
                .viewer-toolbar-time{
                    display: inline-block;
                    background-color: rgba(0,0,0,.8);
                    color: rgba(255,255,255,.5);
                    padding: 0.2em 0.4em;
                    border-radius: 2px;
                    user-select: none;
                }
        
                .viewer-toolbar-step{
                    left: 5px;
                }
        
                .viewer-toolbar-time{
                    right: 5px;
                }
            }

            .viewer-toolbar-nav {
                width: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
        
                button.viewer-toolbar-button {
                    background-color: transparent;
                    border: 0;
                    outline: 0;
                    color: #ffffff;
                    transition: .2s;
                    border-radius: 2px;

                    &:not([disabled]) {
                        cursor: pointer;

                        &:hover {
                            background-color: rgba($primary-color, 0.2);
                        }

                        &:active {
                            background-color: rgba($primary-color, 0.1);
                            color: rgba(#fff, 0.5);
                        }
                    }

                    &[disabled] {
                        color: rgba(#ffffff, 0.4);
                    }
                }
            }
        }    
    }

    .viewer-thumbs{
        position: relative;
        padding: 0.5em;
        background-color: $viewer-bg-color;
        border-radius: 2px;
        overflow: hidden;
        color: white;
        display: grid;
        grid-template-columns: repeat(auto-fit, 1fr);
        grid-auto-flow:column;
        grid-gap: 0.5em;
        justify-content: start;
        overflow: auto;

        .thumb{
            width: 150px;
            aspect-ratio: 16 / 9;
            background-color: lighten($viewer-bg-color, 5%);
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba(255,255,255,.1);
            user-select: none;
            border: 5px solid transparent;
            border-radius: 2px;

            &:nth-child(3) {
                border-color: $primary-color;
            }
        }
    }
}