*, *:before, *:after{
	box-sizing: border-box;
}

html, body {
	background-color: #fcfdff;
	min-height: 100vh;
	margin: 0;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
	min-height: 100vh;

	#app{
		min-height: 100vh;
	}
}