$primary-color: #3f51b5;

.toolbar{
    user-select: none;
    background-color: white;
    font-size: 14px;
    border-bottom: 1px solid rgba(0,0,0,.1);

    .toolbar-section{
        margin: 0 1em;
        align-items: center;
        height: 100%;
        display: none;

        &.show{
            display: flex;
        }

        .label{
            margin-right: 0.5em;
        }

        .date-from, .date-to{
            margin-right: 0.5em;
        }

        .action{
            margin-right: 0.5em;
        }
    }
}