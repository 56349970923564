$border-color: #ddd;

.grid-table-root{
    width: 100%;
    user-select: none;
    background-color: #f9f9f9;

    thead th{
        padding: 0.5em;
        border-bottom: 1px solid $border-color;
        color: rgba(0,0,0,.75);
        font-size: 14px;

        &:not(:last-child){
            border-right: 1px solid $border-color;
        }

        span.field{
            margin-right: 0.5em;
        }
    }

    tbody td{
        border-bottom: 1px solid $border-color;

        &:not(:last-child){
            border-right: 1px solid $border-color;
        }

        &:first-child{
            text-align: center;
            color: var(--primary-color);
            padding: 0.5em;
        }

        &:not(:first-child){
            text-align: right;
            padding: 0;
        }

        input[type=number]{
            font-size: inherit;
            font-family: inherit;
            padding: 0.5em 1.5em;
            margin: 0;
            outline: none;
            border: 0;
            width: 100%;
            user-select: text;
            transition: .2s;
            border: 1px solid transparent;

            &:focus{
                color: var(--primary-color);
            }

            &.state-alert{
                color: var(--error-text-color);
                background-color: var(--error-color);
                border-color: var(--error-text-color);
            }
        }

        &.action-remove-row{
            .action-remove-row-content{
                display: flex;
                padding: 0 0.5em;
    
                button{
                    opacity: 0;
                    transition: .2s;
                }
            }
        }
    }

    tbody tr:hover td.action-remove-row button{
        opacity: 1;
    }

    tbody tr.last-row td{
        background-color: transparent;
        text-align: center;
        cursor: pointer;
        color: rgba(0,0,0,0);
        transition: .2s;
        padding: 0.25em;

        &:hover{
            color: rgba(0,0,0,.75);
        }
    }
}